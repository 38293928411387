


















































































import {
  Component, Mixins, Prop,
} from 'vue-property-decorator';

import ErrorLoading from '@/components/ErrorLoading/ErrorLoading.vue';
import SubjectList from '@/components/Subject/SubjectList/index.vue';
import IconStatus from '@/components/IconStatus/index.vue';
import RegroupType from '@/components/RegroupType/index.vue';

import TopicListLoading from './TopicListLoading.vue';

import HistoricService from '@/services/Historic/historicService';
import { TypeMaterial } from '@/services/Historic/historicInterface';

import { hasPermissionMaterials } from '@/share/Util/Permissions/materials';
import { hasPermissionExercises } from '@/share/Util/Permissions/exercises';

import { LIST_NAME_CONTEXT } from '@/constant/Context';
import { LIST_PERMISSION } from '@/constant/ListPermission';
import { IDS_FOR_PERMISSION_COURSE } from '@/constant/CoursesId';
import { LIST_BOOK_ID } from '@/constant/ListBookId';

import permission from '@/mixins/permission';
import listAccordion from '@/mixins/listAccordion';

const TYPE_MATERIAL = 'material';
const TYPE_EXERCISE = 'exercise';

@Component({
  mixins: [listAccordion],
  components: {
    SubjectList,
    IconStatus,
    RegroupType,
    TopicListLoading,
    ErrorLoading,
  },
})
export default class TopicList extends Mixins(permission) {
  @Prop() type!: string;
  @Prop() contextName!: string;
  @Prop() listTopic!: Record<string, any>;
  @Prop({ default: null }) idBook!: null | number;
  @Prop() loading!: boolean;
  @Prop({ default: false }) errorLoading!: boolean;

  private title = '';
  private idType = '';
  private historicType: TypeMaterial = 'video';

  private ids: Array<number> = [];
  private allChecked: Array<Record<string, any>> = [];

  private historicService = new HistoricService();

  async mounted() {
    await this.loadData();
  }

  get setType() {
    if (this.type === 'exercise') return 'exercices';
    if (this.type === 'video') return 'videos';
    return 'archives';
  }

  get idCourseContext() {
    const { path } = this.$route.params;
    const splitPath = path.split('&');

    return splitPath[1];
  }

  permissionMaterial(file: Record<string, any>) {
    if (this.contextName === LIST_NAME_CONTEXT.PLANNER) {
      return this.can(LIST_PERMISSION.PLANNER);
    }

    if (this.contextName === LIST_NAME_CONTEXT.COURSE) {
      return this.can(IDS_FOR_PERMISSION_COURSE[Number(this.idCourseContext)]);
    }

    if (
      this.idBook === LIST_BOOK_ID.REDACTION
      && this.can(LIST_PERMISSION.ALL_MATERIALS_REDACTION)
    ) return true;

    return hasPermissionMaterials(file);
  }

  permissionTopic(file: Record<string, any>) {
    if (
      this.contextName === LIST_NAME_CONTEXT.PLANNER
      || this.contextName === LIST_NAME_CONTEXT.COURSE
    ) return true;

    if (this.type === TYPE_MATERIAL) {
      return this.permissionMaterial(file);
    }

    if (this.type === TYPE_EXERCISE) {
      return hasPermissionExercises(file);
    }

    return true;
  }

  async loadData() {
    if (this.type === 'exercise') {
      this.getIds(this.listTopic[this.setType], 'idExercice');
      this.idType = 'idExercice';
      this.historicType = 'exercice';
      await this.getAllObjects();
    }
    if (this.type === 'video') {
      this.getIds(this.listTopic[this.setType], 'idVideo');
      this.idType = 'idVideo';
      this.historicType = 'video';
      await this.getAllObjects();
    }
    if (this.type === 'material') {
      this.getIds(this.listTopic[this.setType], 'idArchive');
      this.idType = 'idArchive';
      this.historicType = 'materiais';
      await this.getAllObjects();
    }
  }

  setTitle(title: string) {
    this.title = title;
  }

  getIds(listTopic: Array<Record<string, any>>, idType: string) {
    listTopic.forEach((object: Record<string, any>) => {
      this.ids.push(object[idType]);
    });
  }

  async getAllObjects() {
    if (this.ids.length > 99) {
      const response = await this.getHistoric(this.historicType, this.ids.splice(0, 100));

      this.allChecked.push(...response);
      await this.getAllObjects();
    } else {
      const response = await this.getHistoric(this.historicType, this.ids);

      this.allChecked.push(...response);
    }
  }

  async getHistoric(type: TypeMaterial, listIds: Array<number>) {
    try {
      const response = await this.historicService.getHistoric({ type, listIds });

      return response || [];
    } catch (error) {
      return [];
    }
  }

  isChecked(object: Record<string, any>) {
    let result = false;
    result = this.allChecked?.some((checkedItem: Record<string, any>) => {
      if (checkedItem?.idobject === object[this.idType]) {
        return checkedItem?.checked;
      }
      return false;
    });
    return result;
  }

  setData(item: Record<string, any>) {
    const { context, path } = this.$route.params;

    if (this.type === 'exercise') {
      return {
        to: '/exercicios',
        from: `${context}/${path}`,
        file: item,
        type: 'instructions',
        id: item.idExercice,
      };
    }

    if (this.type === 'video') {
      return {
        to: '/videos',
        from: `${context}/${path}`,
        file: item,
        id: item.idVideo,
      };
    }
    return {
      to: '/materiais',
      from: `${context}/${path}`,
      file: item,
      id: item.idArchive,
    };
  }

  updateAllChecked(event: number) {
    const newAllChecked: Array<Record<string, any>> = this.allChecked;
    newAllChecked.push({
      idobject: event,
      checked: true,
    });
    this.allChecked = newAllChecked;
  }

  getProgress(object: Record<string, any>) {
    let percent = 0;
    if (this.idType === 'idVideo') {
      this.allChecked.forEach((checkedItem) => {
        if (object[this.idType] === checkedItem?.idobject) {
          percent = checkedItem.percent;
        }
      });
    }
    return percent;
  }
}
